
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({})
export default class ContractorNoteRow extends Vue {
    @Prop(Object) public item!: any;
    @Prop(Array) public itemsHeaders!: any;
    @Prop(Boolean) public isActive!: boolean;
}
